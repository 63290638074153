import React from 'react';
import Link from "gatsby-link";

function Solutionslist({data, solutionState}) {
    return (
        <>
            {data && data ? data.map((value, i) => (
                <div className={solutionState === `tab-${value.id}` ? 'main_listing active' : 'main_listing'}>
                    <h4>{value.name}</h4>
                    <ul>
                        {value.solutions && value.solutions.data ? value.solutions.data.map((item, i) => (
                            <>
                                {item.status !== 0 ?
                                    <li>
                                        <Link to={`/solution/${item.slug}`}>{item.name}</Link>
                                    </li>
                                    : ''}
                            </>
                        )) : ''}
                    </ul>
                </div>
            )) : ''}
        </>
    );
}

export default Solutionslist;